import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'home',
        },
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('./view/home'),
        meta: {
            title: '',
        },
    },
    {
        name: 'schoolItem',
        path: '/school/item',
        component: () => import('./view/school/item'),
        meta: {
            title: '学校报名',
        },
    }, 
    {
        name: 'schoolVeteran',
        path: '/school/veteran',
        component: () => import('./view/school/veteran'),
        meta: {
            title: '退伍军人报名入口',
        },
    }, 
    {
        name: 'schoolQuery',
        path: '/school/query',
        component: () => import('./view/school/query'),
        meta: {
            title: '查询报名记录',
        },
    }, 
    {
        name: 'schoolChckeout',
        path: '/school/chckeout',
        component: () => import('./view/school/chckeout'),
        meta: {
            title: '结算台',
        },
    }, 
    {
        name: 'schoolPaid',
        path: '/school/paid',
        component: () => import('./view/school/paid'),
        meta: {
            title: '支付结算',
        },
    },
    {
        name: 'manageHome',
        path: '/manage/home',
        component: () => import('./view/manage/home'),
        meta: {
            title: '学校管理后台',
        },
    },
    {
        name: 'manageWorkticket',
        path: '/manage/workticket',
        component: () => import('./view/manage/workticket'),
        meta: {
            title: '学校管理后台',
        },
    },

];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if ( title ) {

    //document.title = title + ' | 广西直聘人力资源集团';
    document.title = title
    
  } else {
    //document.title = '广西直聘人力资源集团';
    document.title = '';
  }
  
  next();
});

export { router };
